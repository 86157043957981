.app-container {
    position: relative;
    width: 100%;
    min-height: 100vh;
}

.app-container-pc {
    width: max(max(57vw, 729.6px), 729.6px);
    margin: 0 auto 0 auto;
}

.sticky-header {
/*     position: sticky;
    top: 0;
    z-index: 1; */
    width: 100%;
    height: 44px;
    background-color: #ffffff;
    padding-left: 14px;
}

.sticky-header-tb {
/*     position: sticky;
    top: 0;
    z-index: 1; */
    width: 100%;
    height: 72px;
    background-color: #ffffff;
    padding-left: 14px;
}

.sticky-header-pc {
/*     position: sticky;
    top: 0;
    z-index: 1; */
    width: 100%;
    height: 72px;
    background-color: #ffffff;
    padding-left: 14px;
}

.left-arrow-button {
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.left-arrow-button img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.arrow-icon {
    position: absolute;
    top: 0;
    height: 100%;
    font-size: 20px;
}

.sticky-footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    padding: 10px;
    z-index: 1;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
}

.custom-arrow {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: color 0.3s ease-in-out;
    z-index: 10;
}

.prev-arrow {
    left: 20px;
}

.next-arrow {
    right: 20px;
}

.thumbnail-item {
    width: calc(max(57vw, 729.6px) / 1100 * 100);
    height: calc(max(57vw, 729.6px) / 1100 * 75);
    object-fit: contain;
}

.thumbnail-item:hover {
    opacity: 0.6;
    cursor: pointer;
}

.video-container {
    margin-top: 20px;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    /* 16:9 高宽比的视频，即 9 / 16 = 0.5625 */
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.goods-title {
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 30px;
    font-size: 20px;
    font-weight: 700;
}

.goods-title-tb {
    margin-top: 32px;
    margin-bottom: 32px;
    line-height: 30px;
    font-size: 24px;
    font-weight: 700;
}


.goods-title-pc {
    margin-top: 5px;
    margin-bottom: 20px;
    line-height: 42px;
    font-size: 28px;
    font-weight: 700;
}

.goods-sub-head {

    background-color: white;
    height: 45px;
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    color: #089BEE;
}

.goods-info-box {
    background-color: #f6f7fa;
    border-radius: 10px;
}

.goods-info-box-title {
    font-size: 14px;
    font-weight: 700;
    text-align: right;
    width: 25%;
}

.goods-info-box-detail {
    font-size: 14px;
    text-align: left;
    width: 70%;
}

.goods-detail-title {
    margin: 0 0 8px 0;
    font-size: 18px;
    font-weight: 700;
}

.goods-detail-text {
    font-size: 14px;
    line-height: 23.8px;
}

.carousel-size {
    width: 100%;
    height: auto;
    background-color: #f6f7fa;
}
.carousel-size-pc {
    width: 100%;
}

.carousel-size-img {
    width: 100%;
    aspect-ratio: 4/3;
}

/* .thumbnail-item {
    width: 100px;
    height: 75px;
    object-fit: contain;
} */

.carousel-size-img-detail {
    object-fit: contain;
}

.chobit-frame {
    width: 100%;
    aspect-ratio: 4/3;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    /* 这样点击事件会被穿透给iframe */
    background-color: rgba(0, 0, 0, 0.5);
}