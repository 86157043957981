.container {
    display: flex;
    justify-content: center;
    width: 60vw;
    /* 默认宽度为浏览器宽度的60% */
    background-color: white;
}

.custom-button-selected {
    border: 1px solid var(--chakra-colors-pink-300) !important;
    color: white !important;
    background-color: var(--chakra-colors-pink-300) !important;
  }
  .custom-button-unselected {
    border: 1px solid var(--chakra-colors-pink-300) !important;
    color: var(--chakra-colors-pink-300) !important;
    background-color: transparent !important;
  }

.detail{
    padding-right: 10px;
    padding-left: 10px;
}