.detail{
    padding-right: calc(100vw / 360 * 14);
    padding-left: calc(100vw / 360 * 14);
    padding-bottom: 30px;
}

.card {
    width: calc(50vw - 22.5px);
}
.card-pc {
    width: calc((max(57vw, 729.6px) - 240px)/3.0);
}

.card-mark{
    text-align: center;
    font-size: 11px;
    height: 20px;
    line-height: 20px;
}

.card-mark-mobi{
    text-align: left;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-top: 5px;
    margin-left: 12px;
}
.card-title{
    margin-top: calc(max(57vw, 729.6px) / 1100 * 8);
    font-size: calc(max(57vw, 729.6px) / 1100 * 16);
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: calc(max(57vw, 729.6px) / 1100 * 72);
    line-height: calc(max(57vw, 729.6px) / 1100 * 24);
    color: #303345;
}
.card-title-mobi {
    margin-top: calc(100vw / 360 * 4);
    font-size: calc(100vw / 360 * 14);
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: calc(100vw / 360 * 60);
    line-height: calc(100vw / 360 * 20);
    color: #303345;
}
.card-title-tb {
    margin-top: calc(100vw / 820 * 10);
    font-size: calc(100vw / 820 * 16);
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: calc(100vw / 820 * 85);
    line-height: calc(100vw / 820 * 28.33);
    color: #303345;
}
.card-circel-info{
    -webkit-line-clamp: 1; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    margin-top: calc(max(57vw, 729.6px) / 1100 * 8);
    font-size: calc(max(57vw, 729.6px) / 1100 * 13);
    line-height: calc(max(57vw, 729.6px) / 1100 * 16);
    font-weight: 400;
    color: #929BBA;
}
.card-circel-info-mobi{
    -webkit-line-clamp: 1; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    margin-top: calc(100vw / 360 * 4);
    font-size: calc(100vw / 360 * 11);
    font-weight: 400;
    color: #929BBA;
}
.card-circel-info-tb{
    -webkit-line-clamp: 1; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    margin-top: calc(100vw / 820 * 10);
    font-size: calc(100vw / 820 * 12);
    line-height: calc(100vw / 820 * 14);
    font-weight: 400;
    color: #929BBA;
}