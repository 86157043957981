.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.container {
  width: 100vw;
  justify-content: left;
  margin: 0 auto;
}

.container-mobi {
  width: 100vw;
  justify-content: left;
  margin: 0 auto;
}