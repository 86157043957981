.horizontal-carousel-container {
    width: 100%;
    max-width: 100%; /* 设置轮播图容器的最大宽度，你可以根据需要调整 */
    height: 'calc(max(100vw, 1280px) / 1920 * 352)';
    margin-top: 38px;
}

.carousel-div {
  margin: 0;
}

.carousel-div :hover{
  opacity: 0.6;
}
